// src/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Dashboard.css';
import { auth, firestore } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import { signOut } from 'firebase/auth'; // Importa signOut
import UsersContent from './allUsersSection';
import ModifyUserComponent from './modifyUserSection';
import SendEmailComponent from './sendEmailSection';


function Dashboard() {
    const [menuVisible, setMenuVisible] = useState(true);
    const [selectedOption, setSelectedOption] = useState('users');
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [songs, setSongs] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const basicUsers = location.state.users;
    const usersTestMails = [{email: "opaulina.vara@gmail.com"}, {email: "desarrollo@aincard-iam.com"}, {email: "paulina.vara@hotmail.com"}]
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (!user) {
          navigate('/login');
        }
      });
  
      return () => unsubscribe();
    }, [navigate]);
  
    useEffect(() => {
      const fetchUserDetails = async () => {
        const usersCollection = await getDocs(collection(firestore, 'Usuarios'));
        const newUsers = [];
  
        basicUsers.forEach(userAuth => {
          const userDoc = usersCollection.docs.find(doc => doc.data().ID === userAuth.uid);
          if (userDoc) {
            const data = userDoc.data();
            newUsers.push({
              uid: userAuth.uid,
              email: userAuth.email,
              nombre: `${data.nombre} ${data.apellidos}`,
              pais: data.pais,
              telefono: data.telefono,
            });
          }
        });
  
        setUsers(newUsers);
      };
  
      fetchUserDetails();
    }, [basicUsers]);
  
    useEffect(() => {
        const fetchSongs = async () => {
        const songsCollection = await getDocs(collection(firestore, 'Ainsongs'));
        const newSongs = [];
    
        songsCollection.forEach(doc => {
        const data = doc.data();
        newSongs.push({
            uid: doc.id,
            nombre: data.nombre,
        });
        });
    
        // Ordena alfabéticamente por el campo 'nombre'
        newSongs.sort((a, b) => a.nombre.localeCompare(b.nombre));
    
        setSongs(newSongs);
    };
    
    fetchSongs();
    }, []);
      
  
    const handleViewUserDetails = (user) => {
      setSelectedOption('modifyUser');
      setSelectedUser(user);
      window.scrollTo(0, 0);
    };
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 700) {
          setMenuVisible(true);
        }
      };
  
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        console.log('User signed out successfully');
        navigate('/login'); // Redirigir a la página de inicio de sesión
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    };
  
    return (
      <div className="dashboard">
        {/* Columna izquierda */}
        <div className={`menu ${menuVisible ? 'show' : ''}`}>
          <button className="close-menu-button" onClick={() => setMenuVisible(false)}>x</button>
          <center><div className="logo"></div></center>
          <button className="menu-item" onClick={() => {
            setSelectedOption('users');
            if (window.innerWidth <= 700) {
              setMenuVisible(false);
            }
          }}>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td><div className="users-menu-image"></div></td>
                    <td>Usuarios</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </button>
          {/*<button className="menu-item" onClick={() => {
            setSelectedOption('modifyUser');
            if (window.innerWidth <= 700) {
              setMenuVisible(false);
            }
          }}>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td><div className="modify-user-menu-image"></div></td>
                    <td>Modificar usuario</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </button>*/}
          <button className="menu-item" onClick={() => {
            setSelectedOption('sendMail');
            if (window.innerWidth <= 700) {
              setMenuVisible(false);
            }
          }}>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td><div className="send-email-menu-image"></div></td>
                    <td>Mandar email</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </button>
          <button className="logout-btn" onClick={handleSignOut}> {/* Agrega onClick al botón de cierre de sesión */}
            <div>
              <table>
                <tbody>
                  <tr>
                    <td><div className="logout-menu-image"></div></td>
                    <td>Cerrar sesión</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </button>
        </div>
  
        {/* Columna derecha */}
        <div className="content">
          {/* Contenedor con padding */}
          <div className="container">
            <table className="top-table">
              <tbody>
                <tr>
                  <td className='menu-button-cell'>
                    <button className='menu-btn' onClick={() => setMenuVisible(true)}></button>
                  </td>
                  <td className="first-row-admin" colSpan={2}>
                    <table className="top-subtable">
                      <tbody>
                        <tr>
                          <td>
                            <table className="top-subtable">
                              <tbody>
                                <tr>
                                  <td className='table-row'><b>Administrador</b></td>
                                </tr>
                                <tr>
                                  <td className='table-row'>Sitio de administración</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td className="admin-icon-cell"><div className="admin-icon-image"></div></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='page-variable-content'>
              {selectedOption === 'users' && (
                <UsersContent users={users} onViewUserDetails={handleViewUserDetails} />
              )}
              {selectedOption === 'modifyUser' && (
                <ModifyUserComponent user={selectedUser} songs={songs} />
              )}
              {selectedOption === 'sendMail' && <SendEmailComponent basicUsers={usersTestMails} />}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Dashboard;
