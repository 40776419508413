import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import './FontCustomization.css';
import { firestore } from './firebase';
import { collection, getDocs, query, where, addDoc, deleteDoc, doc } from 'firebase/firestore';

const ModifyUserComponent = ({ user = null, songs }) => {
  // Función para dividir las canciones en grupos de tres
  const groupedSongs = [];
  for (let i = 0; i < songs.length; i += 3) {
    groupedSongs.push(songs.slice(i, i + 3));
  }

  const userToDisplay = user;
  const [userSongs, setUserSongs] = useState([]);
  const [userCards, setUserCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [cardToDeactivate, setCardToDeactivate] = useState(null);
  const userUID = user?.uid; // Asegúrate de que `user` esté definido antes de acceder a `uid`
  const aincardIAMUID = "izheF1fAWpFCtutm0CWx";
  const [pendingSong, setPendingSong] = useState(null);

  const checkUidMatch = (uidToCheck) => {
    const exists = userSongs.some(song => song.songID === uidToCheck);
    return exists;
  };

  const handleActivate = async (song) => {
    if (userCards.length === 0) {
      // Mostrar el modal si no hay ninguna Aincard activa
      setPendingSong(song);
      setShowActivateModal(true);
    } else {
      await activateSong(song);
    }
  };

  const activateSong = async (song) => {
    if (!song.uid || !userUID) {
      console.error('No songID or userUID defined');
      return;
    }

    try {
      // Agregar documento a Firestore
      const docRef = await addDoc(collection(firestore, 'Ainsong_Usuario'), {
        id_ainsong: song.uid, // Asegúrate de que `id_ainsong` sea `song.uid`
        id_usuario: userUID,
      });

      // Actualizar el estado para reflejar los cambios
      setUserSongs(prevSongs => [
        ...prevSongs,
        { uid: docRef.id, songID: song.uid } // Usar el ID del documento recién creado
      ]);

      console.log('Song activated and added to Firestore');
    } catch (error) {
      console.error('Error activating song: ', error);
    }
  };

  const handleActivateCard = async () => {
    if (!userUID) {
      console.error('No userUID defined');
      return;
    }

    try {
      // Agregar documento a Firestore
      const docRef = await addDoc(collection(firestore, 'Tarjeta_Usuario'), {
        id_tarjeta: aincardIAMUID,
        id_usuario: userUID,
      });

      // Actualizar el estado para reflejar los cambios
      setUserCards(prevCards => [
        ...prevCards,
        { uid: docRef.id }
      ]);

      console.log('Card activated and added to Firestore');
    } catch (error) {
      console.error('Error activating card: ', error);
    }
  };

  const handleDeactivateCard = async () => {
    try {
      if (cardToDeactivate) {
        handleDeleteAincardFromPlaylists()

        // Elimina el documento correspondiente en Firestore
        await deleteDoc(doc(firestore, 'Tarjeta_Usuario', cardToDeactivate));
        
        // Actualiza el estado después de eliminar la tarjeta
        setUserCards(prevCards => prevCards.filter(card => card.uid !== cardToDeactivate));
        
        console.log('Tarjeta desactivada:', cardToDeactivate);
  
        // Desactivar todos los sonidos asociados
        const deactivatePromises = userSongs.map(userSong => handleDeactivate(userSong.songID));
        await Promise.all(deactivatePromises);
  
        console.log('Todos los sonidos desactivados');
      } else {
        console.log('Tarjeta no encontrada para desactivar:', cardToDeactivate);
      }
  
      // Cierra el modal
      setShowModal(false);
    } catch (error) {
      console.error('Error al desactivar la tarjeta:', error);
    }
  };

  const handleDeleteSongsFromPlaylists = async (songToDelete) => {
    try {
      // Paso 1: Obtener todos los documentos de la colección "Playlists" cuyo campo id_usuario coincida con userUID
      const playlistsQuery = query(collection(firestore, 'Playlists'), where('id_usuario', '==', userUID));
      const playlistsSnapshot = await getDocs(playlistsQuery);
      
      // Paso 2: Guardar un arreglo de strings con el uid de cada documento obtenido
      const playlistIds = playlistsSnapshot.docs.map(doc => doc.id);
  
      // Paso 3: Eliminar registros de la colección "Playlist_Song" que correspondan a los ids obtenidos y al songToDelete
      const deletePromises = playlistIds.map(async playlistId => {
        const playlistSongsQuery = query(collection(firestore, 'Playlist_Song'), where('id_playlist', '==', playlistId), where('id_ainsong', '==', songToDelete));
        const playlistSongsSnapshot = await getDocs(playlistSongsQuery);
  
        const deletePromises = playlistSongsSnapshot.docs.map(doc => deleteDoc(doc.ref));
        return Promise.all(deletePromises);
      });
  
      await Promise.all(deletePromises);
      
      console.log('Canción eliminada de todas las playlists');
    } catch (error) {
      console.error('Error eliminando la canción de las playlists:', error);
    }
  };  

  const handleDeleteAincardFromPlaylists = async () => {
    try {
      // Paso 1: Obtener todos los documentos de la colección "Playlists" cuyo campo id_usuario coincida con userUID
      const playlistsQuery = query(collection(firestore, 'Playlists'), where('id_usuario', '==', userUID));
      const playlistsSnapshot = await getDocs(playlistsQuery);
      
      // Paso 2: Guardar un arreglo de strings con el uid de cada documento obtenido
      const playlistIds = playlistsSnapshot.docs.map(doc => doc.id);
  
      // Paso 3: Eliminar registros de la colección "Playlist_Song" que correspondan a los ids obtenidos y al songToDelete
      const deletePromises = playlistIds.map(async playlistId => {
        const playlistSongsQuery = query(collection(firestore, 'Playlist_Song'), where('id_playlist', '==', playlistId), where('id_ainsong', '==', aincardIAMUID));
        const playlistSongsSnapshot = await getDocs(playlistSongsQuery);
  
        const deletePromises = playlistSongsSnapshot.docs.map(doc => deleteDoc(doc.ref));
        return Promise.all(deletePromises);
      });
  
      await Promise.all(deletePromises);
      
      console.log('Canción eliminada de todas las playlists');
    } catch (error) {
      console.error('Error eliminando la canción de las playlists:', error);
    }
  };  

  const handleDeactivate = async (songUID) => {
    try {
      // Encuentra el objeto correspondiente en userSongs
      const songToDeactivate = userSongs.find(song => song.songID === songUID);
      
      if (songToDeactivate) {
        // Llamar a la función para eliminar la canción de las playlists
        await handleDeleteSongsFromPlaylists(songToDeactivate.songID);
  
        // Elimina el documento correspondiente en Firestore
        await deleteDoc(doc(firestore, 'Ainsong_Usuario', songToDeactivate.uid));
        
        // Actualiza el estado después de eliminar
        setUserSongs(prevSongs => prevSongs.filter(song => song.songID !== songUID));
        
        console.log('Canción desactivada:', songUID);
      } else {
        console.log('Canción no encontrada para desactivar:', songUID);
      }
    } catch (error) {
      console.error('Error al desactivar la canción:', error);
    }
  };
  

  useEffect(() => {
    const fetchUserSongs = async () => {
      try {
        const q = query(collection(firestore, 'Ainsong_Usuario'), where('id_usuario', '==', userUID));
        const querySnapshot = await getDocs(q);
        const newUserSongs = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          newUserSongs.push({
            uid: doc.id,
            songID: data.id_ainsong,
          });
        });

        setUserSongs(newUserSongs);
        console.log(newUserSongs);
      } catch (error) {
        console.error('Error fetching user songs: ', error);
      }
    };

    if (userUID) { // Asegúrate de que `userUID` esté definido antes de hacer la consulta
      fetchUserSongs();
    }
  }, [userUID]);

  useEffect(() => {
    const fetchUserCards = async () => {
      try {
        const q = query(collection(firestore, 'Tarjeta_Usuario'), where('id_usuario', '==', userUID));
        const querySnapshot = await getDocs(q);
        const newUserCard = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          newUserCard.push({
            uid: doc.id,
          });
        });

        setUserCards(newUserCard);
      } catch (error) {
        console.error('Error fetching user cards: ', error);
      }
    };

    if (userUID) { // Asegúrate de que `userUID` esté definido antes de hacer la consulta
      fetchUserCards();
    }
  }, [userUID]);

  return (
    <div>
      <div className='page-title'>Modificar usuario</div>
      <table className="search-individual-table">
        <tbody>
          <tr>
            <td className="search-container-cell">
              <div className="search-container">
                <div className="search-icon">
                  <div className="profile-image"></div>
                </div>
                <input type="text" placeholder="Correo electrónico" className="search-input" />
              </div>
            </td>
            <td><button className="search-button">Buscar</button></td>
          </tr>
        </tbody>
      </table>
      {userToDisplay ? (
        <div className='modify-user-holder'>
          <div className='desktop-row'>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2} className='big-bold'>{userToDisplay.nombre}</td>
                </tr>
                <tr>
                  <td className='small-icon-cell'><div className='modify-user-email-icon'></div></td>
                  <td className='modify-user-email-phone-cell'>{userToDisplay.email}</td>
                </tr>
                <tr>
                  <td className='small-icon-cell'><div className='modify-user-phone-icon'></div></td>
                  <td className='modify-user-email-phone-cell'>{userToDisplay.telefono}</td>
                </tr>
              </tbody>
            </table>
            <div className='sub-row'>
              <div className='aincard-i-am-catalog-img'></div>
              <table>
                <tbody>
                  <tr>
                    <td><center className='normal-light top-margin'>Estado:</center></td>
                  </tr>
                  <tr>
                    <td><center className='normal-bold'>{userCards.length !== 0 ? 'Activo' : 'Inactivo'}</center></td>
                  </tr>
                  <tr>
                    <td>
                      {
                      userCards.length !== 0 ? (
                        <button 
                          className="deactivate-sound-btn"
                          onClick={() => {
                            setCardToDeactivate(userCards[0]?.uid);
                            setShowModal(true);
                          }} // Asume que se desactiva la primera tarjeta
                        >
                          DESACTIVAR
                        </button>
                      ) : (
                        <button 
                          className="activate-sound-btn"
                          onClick={() => handleActivateCard()} // Asume que se activa la primera canción
                        >
                          ACTIVAR
                        </button>
                      )
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='ainsounds-catalog-title'>Ainsounds disponibles</div>
          {groupedSongs.map((group, index) => (
            <div key={index} className='desktop-row-ainsounds'>
              {group.map((song, subIndex) => (
                <div key={subIndex} className='desktop-half-row'>
                  <div className='ainsound-catalog-img normal-bold'>{song.nombre}</div>
                  <table>
                    <tbody>
                      <tr>
                        <td><center className='normal-light'>Estado:</center></td>
                      </tr>
                      <tr>
                        <td><center className='normal-bold'>{checkUidMatch(song.uid) ? 'Activo' : 'Inactivo'}</center></td>
                      </tr>
                      <tr>
                        <td>
                        {
                          checkUidMatch(song.uid) ? (
                            <button 
                              className="deactivate-sound-btn" 
                              onClick={() => handleDeactivate(song.uid)}
                            >
                              DESACTIVAR
                            </button>
                          ) : (
                            <button 
                              className="activate-sound-btn" 
                              onClick={() => handleActivate(song)}
                            >
                              ACTIVAR
                            </button>
                          )
                        }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
              {/* Añadir divs vacíos si el grupo tiene menos de 3 canciones */}
              {group.length < 3 && Array.from({ length: 3 - group.length }).map((_, i) => (
                <div key={`empty-${i}`} className='desktop-half-row'></div>
              ))}
            </div>
          ))}
        </div> 
      ) : (
        <div></div>
      )}

      {/* Modal de confirmación */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Esta acción dará de baja Aincard I Am junto con todos los sonidos que tiene activos el usuario, ¿deseas continuar con esta acción?</p>
            <div className="modal-buttons">
              <button className="modal-button modal-button-blue" onClick={handleDeactivateCard}>Continuar</button>
              <button className="modal-button modal-button-purple" onClick={() => setShowModal(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de activación de Aincard */}
      {showActivateModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Antes de activar cualquier Ainsound es necesario activar Aincard I Am.</p>
            <div className="modal-buttons">
              <button className="modal-button modal-button-blue" onClick={() => setShowActivateModal(false)}>Entendido</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifyUserComponent;
