import React, { useState } from 'react';
import './Dashboard.css';

const UsersContent = ({ users, onViewUserDetails }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 15;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    // Filtrar usuarios según el término de búsqueda
    const filteredUsers = users.filter(user => 
        user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 0);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reiniciar a la primera página al hacer una búsqueda
    };

    return (
        <div>
            <div className='page-title'>Usuarios</div>
            <table className="search-individual-table">
                <tbody>
                    <tr>
                        <td className="search-container-cell">
                            <div className="search-container">
                                <div className="search-icon">
                                    <div className="profile-image"></div>
                                </div>
                                <input 
                                    type="text" 
                                    placeholder="Nombre o email" 
                                    className="search-input" 
                                    value={searchTerm} 
                                    onChange={handleSearch}
                                />
                            </div>
                        </td>
                        <td>
                            <button className="search-button">Buscar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="results-content">
                {currentUsers.map((user, index) => (
                    <div key={index} className="user-container">
                        <div className="user-info">
                            <strong className='vertical-padding-lbl'>{user.nombre}</strong>
                            <span className='vertical-padding-lbl'>{user.email}</span>
                        </div>
                        <button className="user-details-btn" onClick={() => onViewUserDetails(user)}>
                        </button>
                    </div>
                ))}
            </div>
            <div className="pagination">
                <button 
                    className={`pagination-btn ${currentPage === 1 ? 'disabled' : ''}`} 
                    onClick={handlePrevPage}
                >
                    &lt;
                </button>
                <button 
                    className={`pagination-btn ${currentPage === totalPages ? 'disabled' : ''}`} 
                    onClick={handleNextPage}
                >
                    &gt;
                </button>
                <div className="pagination-info">
                    {`${indexOfFirstUser + 1} - ${indexOfLastUser} de ${filteredUsers.length}`}
                </div>
            </div>
        </div>
    );
};

export default UsersContent;
