import React, { useState } from 'react';
import { auth } from './firebase';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import './App.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;

      // Llamada al backend para verificar el administrador y obtener los usuarios
      const response = await fetch('https://us-central1-aincard-344e4.cloudfunctions.net/adminBackend/verifyAdmin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid }),
      });

      if (response.status === 401) {
        await signOut(auth);
        setError("Esta no es una cuenta de administrador");
      } else {
        const users = await response.json();
        navigate('/dashboard', { state: { users } });
      }
    } catch (error) {
        console.log(error);
      setError("Credenciales incorrectas. Por favor, inténtalo de nuevo.");
    }
  };

  return (
    <div className="login-container">
      <div className="background-image"></div>
      <div className="overlay"></div>
      <div className="login-box">
      <form onSubmit={handleLogin}>
        <table className="login-table">
          <tbody>
            <tr>
              <td>
                <div className="logo-container"></div>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="username"><b>Usuario</b></label>
              </td>
            </tr>
            <tr>
              <td align='center'>
                <input type="text" id="username" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="password"><b>Contraseña</b></label>
              </td>
            </tr>
            <tr>
              <td align='center'>
                <input type="password" id="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
              </td>
            </tr>
            <tr>
              <td>
                <button type="submit" className="login-button"><b>LOGIN</b></button>
              </td>
            </tr>
            <tr>
                <td>
                    {error && <center style={{ color: 'red', paddingTop: 20 }}>{error}</center>}
                </td>
            </tr>
          </tbody>
        </table>
       </form>
      </div>
    </div>
  );
};

export default Login;
