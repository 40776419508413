import React, { useState } from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios';

const EmailSender = ({ basicUsers }) => {
  const [content, setContent] = useState('');

  const handleSendEmail = async () => {
    try {
      const response = await axios.post('https://us-central1-aincard-344e4.cloudfunctions.net/adminBackend/sendEmail', {
        emailList: basicUsers.map(user => user.email),
        content,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        alert('Correo enviado exitosamente');
      } else {
        alert('Error al enviar el correo');
      }
    } catch (error) {
      console.error('Error al enviar el correo:', error);
      alert('Error al enviar el correo');
    }
  };

  return (
    <div>
      <h2>Enviar correo</h2>
      <JoditEditor
        value={content}
        onChange={(newContent) => setContent(newContent)}
      />
      <button onClick={handleSendEmail}>Enviar</button>
    </div>
  );
};

export default EmailSender;
